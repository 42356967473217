@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap);
html,
body {
  background-color: #008f8f;
  cursor: url(/b73b95039218d58bcbefb275d4ba9ed7.png), pointer;
  cursor: url(/b73b95039218d58bcbefb275d4ba9ed7.png), auto;
  overflow: hidden;
  box-sizing: border-box;
}

.App {
  position: relative;
}

.App a {
  text-decoration: none;
  color: #000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: WindowsCommand;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "WindowsCommand";
  src: local("WindowsCommand"),
    url(/static/media/windows_command_prompt.2589ca39.woff) format("woff");
}

@font-face {
  font-family: "MSSS";
  src: local("MSSS"),
    url(/static/media/M8pt.21af7574.woff) format("woff");
}

@font-face {
  font-family: "mss";
  src: local("mss"),
    url(/static/media/micross.e3a9c893.ttf) format("truetype");
}
.minimiseStyle {
    -webkit-animation: scale-up-bl 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: scale-up-bl 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-22 22:18:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-bl
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-bl {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
}

@keyframes scale-up-bl {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
}
.typewriter {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    letter-spacing: .15em;
    -webkit-animation:
        typing 2s steps(35, end);
            animation:
        typing 2s steps(35, end);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.terminaltype {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    -webkit-animation:
        typing 2s steps(35, end);
            animation:
        typing 2s steps(35, end);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.terminaltype2 {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    /* letter-spacing: .15em; */
    -webkit-animation:
        typing 2s steps(35, end);
            animation:
        typing 2s steps(35, end);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

.terminaltype3 {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    -webkit-animation:
        typing 2s steps(35, end);
            animation:
        typing 2s steps(35, end);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}

.terminaltype4 {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    -webkit-animation:
        typing 2s steps(35, end);
            animation:
        typing 2s steps(35, end);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;

}

.typewriter2 {
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
    -webkit-animation: opacity 0s;
            animation: opacity 0s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.caret {
    border-right: .15em solid #a8a8a8;
    -webkit-animation: blink-caret .75s step-end infinite;
            animation: blink-caret .75s step-end infinite;

}

.cursor {
    opacity: 1;
    -webkit-animation: opacity .75s step-end infinite;
            animation: opacity .75s step-end infinite;
}

@-webkit-keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes typing {
    from {
        width: 0px;
    }

    to {
        width: 100%;
    }
}

@keyframes typing {
    from {
        width: 0px;
    }

    to {
        width: 100%;
    }
}

@-webkit-keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: #a8a8a8;
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: #a8a8a8;
    }
}
.swirl-in-fwd {
    opacity: 0;
    -webkit-animation: swirl-in-fwd 0.6s ease-out 6s both, swirl-out-bck 0.6s ease 13s;
            animation: swirl-in-fwd 0.6s ease-out 6s both, swirl-out-bck 0.6s ease 13s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.scale-in-center {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 7.6s both, scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 13s;
            animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 7.6s both, scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 13s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;

}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-21 11:47:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}
@keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-21 11:55:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-21 11:58:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
}

@keyframes scale-out-center {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-21 11:59:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-out-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-bck {
    0% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
}

@keyframes swirl-out-bck {
    0% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
}
.enterTerminal {
    -webkit-animation: terminal 4s step-end 2.5s, fade-out 1s ease-out 3s;
            animation: terminal 4s step-end 2.5s, fade-out 1s ease-out 3s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.enterWindows {
    -webkit-animation: flicker-out-1 2.5s linear 4s;
            animation: flicker-out-1 2.5s linear 4s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-22 21:48:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
        height: 100vh;
        width: 100vw;
    }

    99.5% {
        width: 100vw;
        height: 100vh;
    }

    100% {
        opacity: 0;
        height: 0vh;
        width: 0vw;

    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
        height: 100vh;
        width: 100vw;
    }

    99.5% {
        width: 100vw;
        height: 100vh;
    }

    100% {
        opacity: 0;
        height: 0vh;
        width: 0vw;

    }
}


@-webkit-keyframes flicker-out-1 {
    0% {
        opacity: 1;
        height: 100%;

    }

    13.9% {
        opacity: 1;
    }

    14% {
        opacity: 0;
        box-shadow: none;
    }

    14.9% {
        opacity: 0;
        box-shadow: none;
    }

    15% {
        opacity: 1;
    }

    22.9% {
        opacity: 1;
    }

    23% {
        opacity: 0;
        box-shadow: none;
    }

    24.9% {
        opacity: 0;
        box-shadow: none;
    }

    25% {
        opacity: 1;
    }

    34.9% {
        opacity: 1;
    }

    35% {
        opacity: 0;
        box-shadow: none;
    }

    39.9% {
        opacity: 0;
        box-shadow: none;
    }

    40% {
        opacity: 1;
    }

    42.9% {
        opacity: 1;
    }

    43% {
        opacity: 0;
        box-shadow: none;
    }

    44.9% {
        opacity: 0;
        box-shadow: none;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    54.9% {
        opacity: 1;
    }

    55% {
        opacity: 0;
        box-shadow: none;
    }

    69.4% {
        opacity: 0;
        box-shadow: none;
    }

    69.5% {
        opacity: 1;
    }

    69.9% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        box-shadow: none;
    }

    79.4% {
        opacity: 0;
        box-shadow: none;
    }

    79.9% {
        opacity: 1;
    }

    80% {
        opacity: 0;
        box-shadow: none;
    }

    89.8% {
        opacity: 0;
        box-shadow: none;
    }

    89.9% {
        opacity: 1;
        box-shadow: none;
    }

    90% {
        opacity: 0;
        box-shadow: none;
    }

    99% {
        height: 100%;

    }

    100% {
        opacity: 0;
        z-index: -1;
        height: 0%;
    }
}


@keyframes flicker-out-1 {
    0% {
        opacity: 1;
        height: 100%;

    }

    13.9% {
        opacity: 1;
    }

    14% {
        opacity: 0;
        box-shadow: none;
    }

    14.9% {
        opacity: 0;
        box-shadow: none;
    }

    15% {
        opacity: 1;
    }

    22.9% {
        opacity: 1;
    }

    23% {
        opacity: 0;
        box-shadow: none;
    }

    24.9% {
        opacity: 0;
        box-shadow: none;
    }

    25% {
        opacity: 1;
    }

    34.9% {
        opacity: 1;
    }

    35% {
        opacity: 0;
        box-shadow: none;
    }

    39.9% {
        opacity: 0;
        box-shadow: none;
    }

    40% {
        opacity: 1;
    }

    42.9% {
        opacity: 1;
    }

    43% {
        opacity: 0;
        box-shadow: none;
    }

    44.9% {
        opacity: 0;
        box-shadow: none;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    54.9% {
        opacity: 1;
    }

    55% {
        opacity: 0;
        box-shadow: none;
    }

    69.4% {
        opacity: 0;
        box-shadow: none;
    }

    69.5% {
        opacity: 1;
    }

    69.9% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        box-shadow: none;
    }

    79.4% {
        opacity: 0;
        box-shadow: none;
    }

    79.9% {
        opacity: 1;
    }

    80% {
        opacity: 0;
        box-shadow: none;
    }

    89.8% {
        opacity: 0;
        box-shadow: none;
    }

    89.9% {
        opacity: 1;
        box-shadow: none;
    }

    90% {
        opacity: 0;
        box-shadow: none;
    }

    99% {
        height: 100%;

    }

    100% {
        opacity: 0;
        z-index: -1;
        height: 0%;
    }
}


@-webkit-keyframes terminal {
    0% {
        opacity: 1;

    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}


@keyframes terminal {
    0% {
        opacity: 1;

    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}

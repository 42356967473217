.typewriter {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    letter-spacing: .15em;
    animation:
        typing 2s steps(35, end);
    animation-fill-mode: forwards;
}

.terminaltype {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    animation:
        typing 2s steps(35, end);
    animation-fill-mode: forwards;
}

.terminaltype2 {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    /* letter-spacing: .15em; */
    animation:
        typing 2s steps(35, end);
    animation-fill-mode: forwards;
    animation-delay: 0s;
}

.terminaltype3 {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    animation:
        typing 2s steps(35, end);
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.terminaltype4 {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    animation:
        typing 2s steps(35, end);
    animation-fill-mode: forwards;
    animation-delay: 2s;

}

.typewriter2 {
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
    animation: opacity 0s;
    animation-fill-mode: forwards;
}

.caret {
    border-right: .15em solid #a8a8a8;
    animation: blink-caret .75s step-end infinite;

}

.cursor {
    opacity: 1;
    animation: opacity .75s step-end infinite;
}

@keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes typing {
    from {
        width: 0px;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: #a8a8a8;
    }
}
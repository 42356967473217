@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: WindowsCommand;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "WindowsCommand";
  src: local("WindowsCommand"),
    url(./assets/fonts/windows_command_prompt.woff) format("woff");
}

@font-face {
  font-family: "MSSS";
  src: local("MSSS"),
    url('./assets/fonts/M8pt.woff') format("woff");
}

@font-face {
  font-family: "mss";
  src: local("mss"),
    url('./assets/fonts/micross.ttf') format("truetype");
}
.enterTerminal {
    animation: terminal 4s step-end 2.5s, fade-out 1s ease-out 3s;
    animation-fill-mode: forwards;
}

.enterWindows {
    animation: flicker-out-1 2.5s linear 4s;
    animation-fill-mode: forwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-22 21:48:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes fade-out {
    0% {
        opacity: 1;
        height: 100vh;
        width: 100vw;
    }

    99.5% {
        width: 100vw;
        height: 100vh;
    }

    100% {
        opacity: 0;
        height: 0vh;
        width: 0vw;

    }
}


@keyframes flicker-out-1 {
    0% {
        opacity: 1;
        height: 100%;

    }

    13.9% {
        opacity: 1;
    }

    14% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    14.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    15% {
        opacity: 1;
    }

    22.9% {
        opacity: 1;
    }

    23% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    24.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    25% {
        opacity: 1;
    }

    34.9% {
        opacity: 1;
    }

    35% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    39.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    40% {
        opacity: 1;
    }

    42.9% {
        opacity: 1;
    }

    43% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    44.9% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    45% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    54.9% {
        opacity: 1;
    }

    55% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    69.4% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    69.5% {
        opacity: 1;
    }

    69.9% {
        opacity: 1;
    }

    70% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    79.4% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    79.9% {
        opacity: 1;
    }

    80% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    89.8% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    89.9% {
        opacity: 1;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    90% {
        opacity: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    99% {
        height: 100%;

    }

    100% {
        opacity: 0;
        z-index: -1;
        height: 0%;
    }
}


@keyframes terminal {
    0% {
        opacity: 1;

    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}